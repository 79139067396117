@import "https://fonts.googleapis.com/css2?family=Caveat&family=Montserrat&display=swap";
html, body {
  color: #4e636d;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-size: 13pt;
}

input {
  font-family: Montserrat, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.zmmx8W_polaroid {
  width: 85vw;
  height: 89vh;
  z-index: 200;
  border: 30px solid #fff;
  border-bottom-width: 80px;
  margin: 0 auto;
  transition: all 1s;
  position: relative;
  box-shadow: 0 5px 25px #0000001a;
}

.zmmx8W_polaroid.zmmx8W_fullScreen {
  width: 100%;
  height: 100vh;
  border-width: 0;
  animation: 10s infinite zmmx8W_scaleIn;
}

.zmmx8W_polaroid .zmmx8W_photo {
  width: 100%;
  height: 100%;
  background: url("main_photo_landscape.81b9a1d0.jpg") center / cover no-repeat;
}

.zmmx8W_polaroid .zmmx8W_textInPhoto {
  mix-blend-mode: overlay;
  width: 20%;
  height: 40%;
  z-index: 201;
  opacity: 0;
  background: url("S_S_2024.9b8e839b.png") center / contain no-repeat;
  transition: all .2s;
  position: absolute;
  bottom: 16%;
  right: 15%;
}

.zmmx8W_polaroid.zmmx8W_fullScreen .zmmx8W_textInPhoto {
  opacity: 1;
}

.zmmx8W_polaroid .zmmx8W_textBelowPhoto {
  opacity: 1;
  width: 100%;
  height: 80px;
  text-align: center;
  flex-direction: column;
  place-content: center;
  margin: 0 auto;
  transition: opacity .2s;
  display: flex;
}

.zmmx8W_polaroid.zmmx8W_fullScreen .zmmx8W_textBelowPhoto {
  opacity: 0;
}

.zmmx8W_polaroid .zmmx8W_textBelowPhoto img {
  height: 40px;
  width: 108px;
  margin: 0 auto;
}

.zmmx8W_scrollDown {
  width: 200px;
  height: 100px;
  opacity: 0;
  text-align: center;
  transition: all .3s;
  display: block;
  position: absolute;
  bottom: 5%;
  left: calc(50% - 100px);
}

.zmmx8W_scrollDown.zmmx8W_active {
  opacity: .8;
}

.zmmx8W_scrollDown img {
  filter: invert();
  width: 60px;
}

.zmmx8W_scrollDown p {
  color: #fff;
  margin-top: 0;
}

@media (min-width: 601px) and (max-width: 1200px) {
  .zmmx8W_polaroid .zmmx8W_textInPhoto {
    width: 23%;
    bottom: 18%;
    right: 10%;
  }
}

@media (max-width: 600px) {
  .zmmx8W_polaroid {
    border-width: 20px 20px 80px;
  }

  .zmmx8W_polaroid .zmmx8W_photo {
    background-position: 40% 0;
  }

  .zmmx8W_polaroid .zmmx8W_textInPhoto {
    width: 35%;
    height: 30%;
    mix-blend-mode: normal;
    bottom: 130px;
    right: 31%;
    transform: none;
  }

  .zmmx8W_scrollDown {
    bottom: 70px;
  }
}

.C_Feta_button {
  appearance: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #97a95a;
  border: 1px solid #1b1f2326;
  border-radius: 6px;
  padding: 6px 16px;
  font-family: -apple-system, system-ui, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
  box-shadow: 0 1px #1b1f231a;
}

.C_Feta_button:focus:not(:focus-visible):not(.C_Feta_focus-visible) {
  box-shadow: none;
  outline: none;
}

.C_Feta_button:hover {
  background-color: #a3b18a;
}

.C_Feta_button, .C_Feta_button * {
  cursor: pointer;
}

.C_Feta_button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #2ea44f66;
}

.C_Feta_button:disabled {
  opacity: .2;
  color: #fffc;
  cursor: not-allowed;
  background-color: gray;
  border-color: #1b1f231a;
}

.C_Feta_button:active {
  background-color: #a3b18a;
  box-shadow: inset 0 1px #14462033;
}

section.Pes-Jq_rsvp {
  margin-top: 100px;
  margin-bottom: 250px;
}

.Pes-Jq_polaroid {
  height: 580px;
  width: 80vw;
  margin: 0 auto;
  top: 0;
  transform: rotate(2deg);
}

.Pes-Jq_label {
  white-space: nowrap;
  margin-bottom: 29px;
  display: block;
}

.Pes-Jq_textField, .Pes-Jq_filledIn {
  width: 100%;
  border: none;
  border-bottom: 3px solid #0000001a;
  outline: 0;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 14pt;
  transition: all .3s;
  display: block;
}

.Pes-Jq_textField:active, .Pes-Jq_textField:focus, .Pes-Jq_filledIn {
  border-color: #a3b18a;
}

.Pes-Jq_listbox {
  width: 100%;
  background: #fff;
  border-bottom: 3px solid #a3b18a;
  padding: 10px 25px;
  display: block;
}

.Pes-Jq_item, .Pes-Jq_highlightedItem {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Pes-Jq_highlightedItem {
  color: #a3b18a;
}

.Pes-Jq_typeahead {
  color: gray;
}

.Pes-Jq_form {
  height: 100%;
  background: #fff;
  transform: scale(1.01);
}

@media (max-width: 800px) {
  .Pes-Jq_polaroid {
    position: initial;
    height: inherit;
  }

  .Pes-Jq_label {
    margin-bottom: 10px;
  }

  .Pes-Jq_textField, .Pes-Jq_filledIn, .Pes-Jq_label {
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 10pt;
  }

  .Pes-Jq_button button {
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 8pt;
  }
}

@media (max-width: 300px) {
  .Pes-Jq_textField, .Pes-Jq_filledIn, .Pes-Jq_label {
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 8pt;
  }

  .Pes-Jq_button button {
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 8pt;
  }
}

.NjzdVW_container {
  z-index: 1;
  padding-top: 10vh;
  transition: background-color 1s;
  position: relative;
  overflow: hidden;
}

.NjzdVW_darkBackground {
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  background-image: url("lights-background.92a60c76.jpg");
  background-size: cover;
  transition: opacity .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.NjzdVW_darkBackground.NjzdVW_active {
  opacity: 1;
}

.NjzdVW_blanket {
  width: 100vw;
  height: 400px;
  z-index: -1;
  background-image: url("blanket.d1ba2ba8.png");
  background-position: top;
  background-size: cover;
  display: block;
  position: absolute;
  bottom: 0;
}

@media (max-width: 900px) {
  .NjzdVW_container {
    padding-top: 0;
  }

  .NjzdVW_blanket {
    height: 200px;
  }
}

section._0U93VW_whereAndWhen {
  font-size: 25pt;
  position: relative;
  top: -6vh;
}

section._0U93VW_whereAndWhen:before {
  content: "";
  width: 100vw;
  height: 10vw;
  background-image: url("green-top.027e8098.svg");
  background-position: top;
  background-size: cover;
  display: block;
}

section._0U93VW_whereAndWhen:after {
  content: "";
  width: 100vw;
  height: calc(20vw + 100px);
  background-image: url("green-bottom.0e5a63cf.svg");
  background-position: bottom;
  background-size: cover;
  display: block;
}

._0U93VW_infoGrid {
  background-color: #a3b18a;
  grid-template-columns: minmax(320px, auto) minmax(320px, auto);
  align-items: start;
  display: grid;
}

._0U93VW_polaroid {
  height: 0;
  position: relative;
  top: -100px;
  right: -80px;
  transform: scale(1.3);
}

._0U93VW_polaroid1 {
  height: 250px;
  min-width: 300px;
  position: relative;
  left: 70px;
}

._0U93VW_polaroid2 {
  height: 300px;
  min-width: 300px;
  z-index: 3;
  position: relative;
  top: -110px;
  left: -30px;
}

._0U93VW_polaroid3 {
  height: 250px;
  min-width: 300px;
  z-index: 2;
  position: relative;
  top: -200px;
  left: 50px;
}

._0U93VW_text {
  color: #48593a;
  margin-left: 10vw;
  margin-right: 10vw;
}

._0U93VW_text address, ._0U93VW_text ._0U93VW_small {
  font-size: 17pt;
  font-style: normal;
}

._0U93VW_noWrap {
  white-space: nowrap;
}

._0U93VW_date {
  grid-template-columns: 0fr 1fr;
  align-items: center;
  display: grid;
}

._0U93VW_locationGrid {
  grid-template-columns: 0fr 1fr;
  grid-auto-rows: min-content;
  align-items: center;
  display: grid;
}

._0U93VW_locationGrid * {
  cursor: pointer;
}

._0U93VW_locationGrid ._0U93VW_icon {
  grid-row: 1 / span 3;
}

._0U93VW_icon {
  width: 50px;
  margin-right: 1rem;
}

._0U93VW_presentGrid {
  grid-template-columns: 0fr 1fr;
  grid-auto-rows: min-content;
  align-items: center;
  display: grid;
}

._0U93VW_presentGrid ._0U93VW_icon {
  grid-row: 1 / span 2;
}

@media (max-width: 1300px) {
  section._0U93VW_whereAndWhen {
    font-size: 17pt;
  }

  ._0U93VW_text address, ._0U93VW_text ._0U93VW_small {
    font-size: 14pt;
  }
}

@media (max-width: 900px) {
  ._0U93VW_infoGrid {
    grid-template-columns: minmax(320px, auto);
    justify-content: center;
  }

  section._0U93VW_whereAndWhen {
    grid-gap: 50px;
    grid-template-columns: 1fr;
  }

  ._0U93VW_polaroid {
    position: initial;
    transform: scale(.8);
  }

  ._0U93VW_locationGrid, ._0U93VW_presentGrid {
    grid-template-columns: 0fr 1fr;
    grid-auto-rows: min-content;
    align-items: center;
    display: grid;
  }

  ._0U93VW_text {
    margin-bottom: 50px;
    margin-left: 7vw;
    margin-right: 7vw;
  }

  section._0U93VW_whereAndWhen {
    margin-bottom: 350px;
  }

  ._0U93VW_icon {
    width: 35px;
  }
}

@media (max-width: 319px) {
  ._0U93VW_infoGrid {
    grid-template-columns: minmax(280px, auto);
  }

  section._0U93VW_whereAndWhen {
    font-size: 14pt;
  }

  ._0U93VW_text address, ._0U93VW_text ._0U93VW_small {
    font-size: 12pt;
  }
}

.b0kRjq_photo {
  width: 100%;
  height: 100%;
  max-height: 100vh;
}

.b0kRjq_photo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.b0kRjq_textBelowPhoto {
  opacity: 1;
  width: 100%;
  text-align: center;
  color: #48593a;
  flex-direction: column;
  place-content: center;
  margin: 3% auto;
  font-family: Caveat, sans-serif;
  transition: all .2s;
  display: flex;
}

.TezmCa_polaroid {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  z-index: 200;
  border: solid #fff;
  margin: 0 auto;
  transition: all 1s;
  position: relative;
  box-shadow: 0 5px 25px #0000001a;
}

.TezmCa_polaroid.TezmCa_flipped {
  transform: rotateY(180deg);
}

.TezmCa_polaroid .TezmCa_front {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  perspective: 2000px;
  -webkit-backface-visibility: hidden !important;
}

.TezmCa_polaroid .TezmCa_front * {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden !important;
}

.TezmCa_polaroid .TezmCa_back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  perspective: 2000px;
  min-height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  transform: rotateY(180deg)scale(1.01);
  -webkit-backface-visibility: hidden !important;
}

section.xMYIwW_poppedTheQuestion {
  grid-template-columns: minmax(30vw, 50vw) minmax(40vw, auto);
  align-items: center;
  margin-top: 100px;
  display: grid;
}

.xMYIwW_polaroid {
  height: 75vh;
  width: 50vw;
  margin: 0 auto;
  top: 0;
}

.xMYIwW_text {
  margin-left: 100px;
  margin-right: 100px;
}

.xMYIwW_textSmall {
  color: #72849d;
  font-size: 20pt;
}

.xMYIwW_textBig {
  color: #72849d;
  font-size: 25pt;
}

@media (max-width: 900px) {
  section.xMYIwW_poppedTheQuestion {
    grid-gap: 50px;
    grid-template-columns: 1fr;
  }

  .xMYIwW_text {
    text-align: center;
    grid-row: 1;
    margin-left: 7vw;
    margin-right: 7vw;
  }

  .xMYIwW_textSmall {
    font-size: 16pt;
  }

  .xMYIwW_textBig {
    font-size: 20pt;
  }

  .xMYIwW_polaroid {
    position: initial;
    width: 80vw;
    height: 80vh;
    grid-row: 2;
  }
}

/*# sourceMappingURL=index.b5dd68ec.css.map */
